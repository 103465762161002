import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface KioskState {
  vatGroups: number[];
  wheelchairFriendly: boolean;
}

const initState: KioskState = {
  vatGroups: [1],
  wheelchairFriendly: false,
};
export const kioskSlice = createSlice({
  name: "kiosk",
  initialState: initState,
  reducers: {
    kioskResetted: () => {},
    setWheelchairFriendly: (state, action: PayloadAction<boolean>) => {
      state.wheelchairFriendly = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { kioskResetted, setWheelchairFriendly } = kioskSlice.actions;

export default kioskSlice.reducer;
